import React, { useContext } from "react";
import styled from 'styled-components';
import { FaLinkedin, FaGithub, FaEnvelope } from 'react-icons/fa';
import { Link as ScrollLink } from "react-scroll";
import { LanguageContext } from "./LanguageContext";

const HomeFooter = ({ id }) => {
  const currentYear = new Date().getFullYear();
  const { language, translations } = useContext(LanguageContext);
  const { backToTop, contactMe, downloadResume,name,profession } = translations[language].footer;
  const { about, projects, skills, resume } = translations[language].nav;
  const { linkedin,github} = translations[language].socialLinks;

  return (
    <FooterContainer id={id}>
      <ScrollLink to="about" smooth={true} duration={500}>
        <BackToTopButton>{backToTop}</BackToTopButton>
      </ScrollLink>
      <Content>
        <LeftSection>
          <Name>{name}</Name>
          <Tagline>{profession}</Tagline>
        </LeftSection>
        <MiddleSection>
          <NavLink href="#about">{about}</NavLink>
          <NavLink href="#projects">{projects}</NavLink>
          <NavLink href="#skills">{skills}</NavLink>
          <NavLink href={downloadResume.link} download>Resume</NavLink>
        </MiddleSection>
        <RightSection>
          <SocialLinks>
            <SocialLink href={linkedin} target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </SocialLink>
            <SocialLink href={github} target="_blank" rel="noopener noreferrer">
              <FaGithub />
            </SocialLink>
            <SocialLink href={`mailto:${contactMe}`}>
              <FaEnvelope />
            </SocialLink>
          </SocialLinks>
        </RightSection>
      </Content>
      <Copyright>
        © {currentYear} Elaheh Ahmadi. All Rights Reserved.
      </Copyright>
    </FooterContainer>
  );
};

export default HomeFooter;

const FooterContainer = styled.footer`
  background-color:(--primary-color);
  padding: 3rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 2rem;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const LeftSection = styled.div`
  text-align: left;
`;

const Name = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`;

const Tagline = styled.p`
  font-size: 1rem;
  color: #666;
`;

const MiddleSection = styled.nav`
  display: flex;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const NavLink = styled.a`
  color: #333;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #1a3722;
  }
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
`;

const SocialLink = styled.a`
  color: #333;
  font-size: 1.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: #1a3722;
  }
`;

const BackToTopButton = styled.button`
  background-color:transparent;
  color: gray;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 1rem;
font-size: x-small;
  &:hover {
    background-color:transparent;
  }
`;

const Copyright = styled.p`
  font-size: 0.9rem;
  color: #666;
  text-align: center;
  margin-top: 1rem;
`;