import React, { useState, useRef } from "react";
import styled from "styled-components";

const Project1 = ({ coverImage, hoverMedia, isVideo, title, tecs, about, prevLink, repoLink }) => {
  const [isHovered, setIsHovered] = useState(false);
  const videoRef = useRef(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (isVideo && videoRef.current) {
      videoRef.current.play();
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (isVideo && videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  return (
    <ProjectCard
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ProjectMediaWrapper>
        {!isHovered && <ProjectImage src={coverImage} alt={title} />}
        {isHovered && (
          isVideo ? (
            <ProjectVideo ref={videoRef} loop muted>
              <source src={hoverMedia} type="video/mp4" />
              Your browser does not support the video tag.
            </ProjectVideo>
          ) : (
            <ProjectImage src={hoverMedia} alt={title} />
          )
        )}
      </ProjectMediaWrapper>
      <ProjectContent>
        <ProjectTitle>{title}</ProjectTitle>
        <TagContainer>
          {tecs.map((tech, index) => (
            <Tag key={index}>{tech}</Tag>
          ))}
        </TagContainer>
        <ProjectDescription>{about}</ProjectDescription>
        <ButtonContainer>
         
          <ProjectButton href={repoLink} target="_blank" rel="noopener noreferrer">
            Github repo
          </ProjectButton>
        </ButtonContainer>
      </ProjectContent>
    </ProjectCard>
  );
}

export default Project1;

const ProjectCard = styled.div`
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  width: 360px; // Fixed width for larger screens
  flex-shrink: 0; // Prevent shrinking in flex container

  @media (max-width: 768px) {
    width: 100%; // Full width on smaller screens
    max-width: 500px; // Limit maximum width on smaller screens
  }

  &:hover {
    transform: translateY(-5px);
  }
`;

const ProjectMediaWrapper = styled.div`
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
`;

const ProjectVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ProjectContent = styled.div`
  padding: 20px;
`;

const ProjectTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;
`;

const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 10px;
`;

const Tag = styled.span`
  background-color: #e0e0e0;
  color: #333;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
`;

const ProjectDescription = styled.p`
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const ProjectButton = styled.a`
  display: inline-block;
  padding: 8px 16px;
  background-color: #1a3722;
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2c5738;
  }
`;