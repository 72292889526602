import React, { useContext } from 'react';
import styled from 'styled-components';
import { FaCode, FaDatabase, FaPaintBrush, FaTools, FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { Link as ScrollLink } from "react-scroll";
import { LanguageContext } from "./LanguageContext";
import { ArrowButton } from './styles/SharedComponents';

const Skills = () => {
  const { language, translations } = useContext(LanguageContext);
  const { skills } = translations[language];

  const skillsData = [
    {
      category: skills.frontend,
      icon: <FaCode />,
      skills: ['JavaScript', 'React', 'HTML', 'CSS']
    },
    {
      category: skills.backend,
      icon: <FaDatabase />,
      skills: ['MongoDB', 'Node.js', 'Express.js','RESTful API']
    },
    {
      category: skills.design,
      icon: <FaPaintBrush />,
      skills: [skills.uxDesign, skills.wireframing, 'Adobe Photoshop', 'Adobe Illustrator']
    },
    {
      category: skills.tools,
      icon: <FaTools />,
      skills: ['Git']
    }
  ];

  return (
    <SkillsContainer id="skills">
     
      <SkillsTitle>{skills.title}</SkillsTitle>
      <SkillsGrid>
        {skillsData.map((category, index) => (
          <SkillCategory key={index}>
            <CategoryIcon>{category.icon}</CategoryIcon>
            <CategoryTitle>{category.category}</CategoryTitle>
            <SkillsList>
              {category.skills.map((skill, skillIndex) => (
                <SkillItem key={skillIndex}>{skill}</SkillItem>
              ))}
            </SkillsList>
          </SkillCategory>
        ))}
      </SkillsGrid>
     
    </SkillsContainer>
  );
};

export default Skills;

const SkillsContainer = styled.section`
  padding: 4rem 1rem;
  background-color: #ffffff;
  position: relative;
  margin-top: 3.5rem;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 3rem 1rem;
  }
`;

const SkillsTitle = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 3rem;
  color: #2E1440;

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
`;

const SkillsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

const SkillCategory = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 768px) {
    padding: 1.5rem;
  }
`;

const CategoryIcon = styled.div`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #1a3722;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const CategoryTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #2E1440;

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }
`;

const SkillsList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const SkillItem = styled.li`
  margin-bottom: 0.5rem;
  color: #333;
  font-size: 1rem;

  &:before {
    content: '•';
    color: #1a3722;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;