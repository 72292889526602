import React from "react";
import styled from "styled-components";
import AboutMe from "./AboutMe";
import HomeFooter from "./HomeFooter";
import Navbar from "./Navbar";
import Projects from "./Projects";
import { Element } from "react-scroll";
import Skills from "./Skills";
import ProfessionalSummary from "./ProfessionalSummary";
import SEOMetaTags from './seo/SEOMetaTags';

const LandPage = ({ language }) => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Person",
    "name": "Elaheh Ahmadi",
    "url": "https://www.elahehahmadi.me", 
    "jobTitle": "Web Developer",
    "description": "Professional web developer specializing in React, JavaScript, MongoDB, and UX design.",
    "skills": ["React", "JavaScript", "MongoDB", "UX Design"],
    "sameAs": [
      "https://www.linkedin.com/in/elaheh-ahmadi-bidakhavidi/",
      "https://github.com/Elaheh-AB"
    ]
  };
  return (
    <Wrapper>
       <structuredData data={structuredData} />
       <SEOMetaTags 
        title="Elaheh Ahmadi - Web Developer Portfolio"
        description="Professional portfolio of Elaheh Ahmadi, showcasing web development projects and skills in React, JavaScript, and more."
        keywords="web developer, portfolio, react, javascript, mongodb, ux design"
      />
      <Navbar />
      <Element name="about">
        <Section>
          <AboutMe />
        </Section>
      </Element>
      <Element name="summary">
        <Section>
          <ProfessionalSummary />
        </Section>
      </Element>
      <Element name="skills">
        <Section>
          <Skills />
        </Section>
      </Element>
      <Element name="projects">
        <Section>
          <Projects />
        </Section>
      </Element>
      
      <Element name="footer">
        <LastSection>
          <HomeFooter />
        </LastSection>
      </Element>
     
    </Wrapper>
  );
};

export default LandPage;

const Wrapper = styled.div`
  width: 100%;
  overflow-x: hidden;
`;

const Section = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
`;
const LastSection = styled.div`
  min-height: fit-content;
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
`;