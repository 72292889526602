import Navbar from "./Navbar";

 const ContactMe=({language})=> {

    return (
     
      <div>
         <Navbar/>
        <p>Hi ContactMe</p>
      </div>
    );
  }
  
  export default ContactMe;
  