import React, { useContext } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { LanguageContext } from "./LanguageContext";
import { HiMenu, HiX } from "react-icons/hi";
import { FaLinkedin, FaGithub, FaDownload, FaEnvelope } from "react-icons/fa";

const Navbar = ({}) => {
  let url = window.location.pathname;
  
  const { language, setLanguage, translations } = useContext(LanguageContext);
  const { linkedin, github } = translations[language].socialLinks;
  const { nav } = translations[language];

  const toggleLanguage = (lang) => {
    setLanguage(lang);
  };

  console.log(url);

  return (
    <Wrapper>
      <WrapperFirstSection>
        <DownloadButton href={nav.resumeLink} download>
          <FaDownload /> {nav.downloadCV}
        </DownloadButton>
      </WrapperFirstSection>

      <WrapperSecondSection>
        <SocialIcons>
          <a href={linkedin} target="_blank" rel="noopener noreferrer">
            <FaLinkedin />
          </a>
          <a href={github} target="_blank" rel="noopener noreferrer">
            <FaGithub />
          </a>
          <a href={`mailto:${nav.contactMe}`}>
            <FaEnvelope />
          </a>
        </SocialIcons>
      </WrapperSecondSection>

      <WrapperThirdSection>
        <LanguageLink 
          onClick={() => toggleLanguage("en")}
          isActive={language === "en"}
        >
          En
        </LanguageLink>
        |
        <LanguageLink
          onClick={() => toggleLanguage("fr")}
          isActive={language === "fr"}
          style={{ marginRight: "10px" }}
        >
          FR
        </LanguageLink>
      </WrapperThirdSection>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ isScrolled }) => (isScrolled ? "#FDFFFC" : "transparent")};
  box-shadow: ${({ isScrolled }) => (isScrolled ? "0 2px 4px rgba(0,0,0,0.1)" : "none")};
  z-index: 999;
  font-size: small;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: var(--header-height);
  overflow: hidden;
  background: white;
  padding: 15px;

  @media (max-width: 900px) {
    flex-direction: column;
    height: fit-content;
    align-items: center;
  }
`;

const WrapperFirstSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const WrapperSecondSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WrapperThirdSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 1rem;
  font-size: 1.2rem;

  a {
    color: #4A5759;
    transition: color 0.3s ease;

    &:hover {
      color: #1a3722;
    }
  }
`;

const DownloadButton = styled.a`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #1a3722;
  color: #FDFFFC;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  font-size: small;

  &:hover {
    background-color: #4A5759;
  }
`;

const LanguageLink = styled.span`
  cursor: pointer;
  padding: 3px;
  text-decoration: ${props => props.isActive ? 'underline' : 'none'};
  text-underline-offset: 4px;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 4px;
  }
`;

export default Navbar;