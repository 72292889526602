import React, { useContext, useState } from "react";
import styled from "styled-components";
import { LanguageContext } from "./LanguageContext";
import { Link as ScrollLink } from "react-scroll";
import { FaChevronDown } from 'react-icons/fa';
import { ArrowButton } from './styles/SharedComponents';

const AboutMe = () => {
  const { language, translations } = useContext(LanguageContext);
  const { salutation, title, desc, workButton } = translations[language].aboutMe;
  
  const [hover, setHover] = useState("no");
  const handleHover = () => setHover("yes");
  const handleOut = () => setHover("no");
  const handleRightClick = (event) => event.preventDefault();

  return (
    <Wrapper id="about">
      <Content>
        <ImageWrapper onContextMenu={handleRightClick}>
          <StyledImage 
            hover={hover}
            src={hover === "no" ? "/ppbw.jpg" : "/pp.jpg"}
            alt="Profile picture"
          /> 
          <Overlay />
        </ImageWrapper>
        
        <Info>
          <Title>
            {salutation}{" "}
            <HighlightedTitle hover={hover}>{title}</HighlightedTitle>
          </Title>
          <Description>{desc}</Description>

          <Btn>
            <ScrollLink to="projects" smooth={true} duration={500}>
              <Button
                onMouseEnter={handleHover}
                onMouseLeave={handleOut}
              >
                {workButton}
              </Button>
            </ScrollLink>
          </Btn>
        </Info>
      </Content>
     
    </Wrapper>
  );
};

export default AboutMe;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  overflow: hidden;
  text-align: center;
  padding: 20px;
  position: relative;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  gap: 40px;

  @media (max-width: 968px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 300px;
  height: auto;
  min-width: 200px;
  object-fit: contain;

  @media (max-width: 768px) {
    width: 250px;
    min-width: 150px;
  }

  @media (max-width: 480px) {
    width: 200px;
    min-width: 120px;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  margin: 15px;
  transition: filter ${props => (props.hover === "yes" ? "3s" : "2s")} ease-in;
  filter: grayscale(${({hover}) => (hover === "no" ? "100%" : "0%")});
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Info = styled.div`
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;

  @media (max-width: 968px) {
    text-align: center;
    max-width: 100%;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 20px;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.8rem;
  }
`;

const HighlightedTitle = styled.span`
  color: #f9f9f4;
  background-color: ${props => (props.hover === "no" ? "#10100f" : "#1a3722")};
  padding: 0 5px;
`;

const Description = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 30px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
    margin-bottom: 20px;
  }
`;

const Btn = styled.div`
  display: flex;
  margin-top: 10px;
  justify-content: flex-start;

  @media (max-width: 968px) {
    justify-content: center;
  }
`;

const Button = styled.button`
  min-width: fit-content;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #1a3722;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #2c5738;
  }

  @media (max-width: 480px) {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
`;