import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { scroller } from "react-scroll";

const sections = ['about', 'summary', 'skills', 'projects', 'footer'];

const FloatingArrows = () => {
  const [showArrows, setShowArrows] = useState(false);
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);

  useEffect(() => {
    const toggleArrows = () => {
      const scrollPosition = window.scrollY;
      setShowArrows(scrollPosition > 50);
    };

    const updateCurrentSection = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      if (scrollPosition >= documentHeight - 50) {
        // We're at the bottom of the page
        setCurrentSectionIndex(sections.length - 1);
      } else {
        for (let i = sections.length - 2; i >= 0; i--) {
          const section = document.getElementById(sections[i]);
          if (section && window.scrollY >= section.offsetTop - 100) {
            setCurrentSectionIndex(i);
            break;
          }
        }
      }
    };

    window.addEventListener('scroll', toggleArrows);
    window.addEventListener('scroll', updateCurrentSection);
    return () => {
      window.removeEventListener('scroll', toggleArrows);
      window.removeEventListener('scroll', updateCurrentSection);
    };
  }, []);

  const scrollToSection = (direction) => {
    let nextIndex;
    if (direction === 'up') {
      nextIndex = Math.max(0, currentSectionIndex - 1);
    } else {
      nextIndex = Math.min(sections.length - 1, currentSectionIndex + 1);
    }

    if (nextIndex !== currentSectionIndex) {
      scroller.scrollTo(sections[nextIndex], {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
      setCurrentSectionIndex(nextIndex);
    }
  };

  const showUpArrow = currentSectionIndex > 0;
  const showDownArrow = currentSectionIndex < sections.length - 1;

  return (
    <>
      {showArrows && (
        <ArrowContainer>
          {showUpArrow && (
            <ArrowButton 
              direction="up" 
              onClick={() => scrollToSection('up')}
            />
          )}
          {showDownArrow && (
            <ArrowButton 
              direction="down" 
              onClick={() => scrollToSection('down')}
            />
          )}
        </ArrowContainer>
      )}
    </>
  );
};

const ArrowContainer = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
`;

const ArrowButton = styled.button`
  width: 40px;
  height: 40px;
  background-color: rgba(233, 233, 230, 0.8);
  clip-path: ${props => props.direction === 'up' 
    ? 'polygon(50% 0%, 0% 100%, 100% 100%)'
    : 'polygon(50% 100%, 0% 0%, 100% 0%)'};
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 5px 0;
  border: none;
  outline:0px;
border-radius: 10px;
  &:hover {
    background-color: var(--success);
    outline:0px;
  }
`;

export default FloatingArrows;