import Navbar from "./Navbar";
 const Resume=({language})=> {

    return (
      <div>
         <Navbar/>
        <p>Hi Resume</p>
      </div>
    );
  }
  
  export default Resume;
  