import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import LandPage from './LandPage';
import Project1 from "./Project1";
import Resume from "./Resume";
import ContactMe from "./ContactMe";
import Projects from "./Projects";
import GlobalStyles from "./GlobalStyles";

import FloatingArrows from "./styles/FloatingArrows";

const App = () => {
  useEffect(() => {
    // Load the script
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-SXLMF6RVT7`;
    script.async = true;
    document.head.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-SXLMF6RVT7');
  }, []);
  const [language, setLanguage] = useState(() => {
    const storedLanguage = localStorage.getItem("language");
    return storedLanguage ? storedLanguage : "English";
  });

  useEffect(() => {
    localStorage.setItem("language", language);
  }, [language]);

  return (
    <>
      <GlobalStyles />
      <Router>
     
        <Routes>
          <Route path="/" element={<LandPage language={language} />} />
          <Route path="/Resume" element={<Resume language={language} />} />
          <Route path="/contactme" element={<ContactMe language={language} />} />
          <Route path="/Projects" element={<Projects language={language} />} />
          <Route path="/projects/project1" element={<Project1 language={language} />} />
          {/* Catch-all route for undefined routes */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <FloatingArrows />
       
      </Router>
    </>
  );
}

export default App;