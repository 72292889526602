import React, { useState, useContext, useRef, useEffect } from "react";
import styled from "styled-components";
import { LanguageContext } from "./LanguageContext";
import Project1 from "./Project1";
import { FaChevronLeft, FaChevronRight,FaChevronUp } from 'react-icons/fa';
import { ArrowButton } from './styles/SharedComponents';

const Projects = () => {
  const { language, translations } = useContext(LanguageContext);
  const { title, projects, filterAll } = translations[language].projects;
  
  const [activeFilter, setActiveFilter] = useState('All');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const carouselRef = useRef(null);

  const allProjects = projects.map(project => ({
    ...project,
    tecs: project.technologies
  }));

  const filteredProjects = activeFilter === 'All'
    ? allProjects
    : allProjects.filter(project => project.tecs.includes(activeFilter));

  const uniqueTechnologies = ['All', ...new Set(allProjects.flatMap(project => project.tecs))];

  const nextProject = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % filteredProjects.length);
    setTimeout(() => setIsTransitioning(false), 500);
  };

  const prevProject = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentIndex((prevIndex) => (prevIndex - 1 + filteredProjects.length) % filteredProjects.length);
    setTimeout(() => setIsTransitioning(false), 500);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft') prevProject();
      if (event.key === 'ArrowRight') nextProject();
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  useEffect(() => {
    setCurrentIndex(0);
  }, [activeFilter]);

  const getProjectStyle = (index) => {
    const diff = (index - currentIndex + filteredProjects.length) % filteredProjects.length;
    let translateX = 0;
    let scale = 1;
    let zIndex = 1;
    let opacity = 1;

    if (diff === 0) {
      translateX = 0;
      scale = 1;
      zIndex = 3;
    } else if (diff === 1 || diff === filteredProjects.length - 1) {
      translateX = diff === 1 ? 105 : -105;
      scale = 0.8;
      zIndex = 2;
      opacity = 0.7;
    } else {
      translateX = diff === 2 ? 250 : -250;
      scale = 0.6;
      zIndex = 1;
      opacity = 0.5;
    }

    return {
      transform: `translateX(${translateX}%) scale(${scale})`,
      zIndex,
      opacity,
      transition: 'all 0.3s ease'
    };
  };

  return (
    <Wrapper id="projects">
      
      <Title>{title}</Title>
      <FilterButtons>
        {uniqueTechnologies.map(tech => (
          <FilterButton 
            key={tech} 
            active={activeFilter === tech}
            onClick={() => setActiveFilter(tech)}
          >
            {tech === 'All' ? filterAll : tech}
          </FilterButton>
        ))}
      </FilterButtons>
      <CarouselContainer>
        <NavigationArrow onClick={prevProject} direction="left">
          <FaChevronLeft />
        </NavigationArrow>
        <CarouselWrapper ref={carouselRef}>
          {filteredProjects.map((project, index) => (
            <ProjectItem 
              key={index}
              style={getProjectStyle(index)}
            >
              <Project1 {...project} />
            </ProjectItem>
          ))}
        </CarouselWrapper>
        <NavigationArrow onClick={nextProject} direction="right">
          <FaChevronRight />
        </NavigationArrow>
      </CarouselContainer>
      <ProjectIndicators>
        {filteredProjects.map((_, index) => (
          <Indicator key={index} active={index === currentIndex} />
        ))}
      </ProjectIndicators>
    </Wrapper>
  );
}

export default Projects;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  background-color: #ffffff;
 
`;

const Title = styled.h2`
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;
  
`;

const FilterButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 30px;
`;

const FilterButton = styled.button`
  padding: 5px 10px;
  border: none;
  background-color: transparent;
  color: ${props => props.active ? '#1a3722' : '#666'};
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;
  border-bottom: 2px solid ${props => props.active ? '#1a3722' : 'transparent'};

  &:hover {
    color: #1a3722;
  }
`;

const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  position: relative;
`;

const CarouselWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 600px;
  position: relative;
  overflow: hidden;
`;

const ProjectItem = styled.div`
  position: absolute;
  width: 100%;
  max-width: 350px;
  height: 100%;
`;

const NavigationArrow = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
  transition: color 0.3s ease;
  z-index: 4;
  padding: 10px;
outline: none;
  &:hover {
    //color: #1a3722;
    outline: none;
  }

  &:focus {
    outline: none;
  }
`;

const ProjectIndicators = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Indicator = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${props => props.active ? '#1a3722' : '#ddd'};
  margin: 0 5px;
  transition: background-color 0.3s ease;
`;