import React, { useContext } from 'react';
import styled from 'styled-components';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
import { ArrowButton } from './styles/SharedComponents';
import { LanguageContext } from "./LanguageContext";

const ProfessionalSummary = () => {
  const { language, translations } = useContext(LanguageContext);
  const { summary } = translations[language];

  return (
    <SummaryContainer id="summary">
      

      <SummaryContent>
        <SummaryTitle>{summary.title}</SummaryTitle>
        <SummaryText>
          {summary.content}
        </SummaryText>
      </SummaryContent>

      
    </SummaryContainer>
  );
};

export default ProfessionalSummary;

const SummaryContainer = styled.section`
  padding: 4rem 2rem;
  background-color: #ffffff;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SummaryContent = styled.div`
  margin: 2rem 0;
  padding: 3rem ;
  background-color: #f9f9f9;
`;

const SummaryTitle = styled.h2`
  font-size: 2rem;
  color: #2E1440;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const SummaryText = styled.p`
  font-size: 1.1rem;
  line-height: 1.6;
  color: #333;
  text-align: justify;
`;