// src/styles/SharedComponents.js

import styled from 'styled-components';
import { Link as ScrollLink } from "react-scroll";

export const ArrowButton = styled(ScrollLink)`
  position: absolute;
  ${props => props.direction === 'up' ? 'top: 10px;' : 'bottom: 10px;'}
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(46, 20, 64, 0.3);
  color: rgba(255, 255, 255, 0.7);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.8rem;

  &:hover {
    background-color: rgba(26, 55, 34, 0.8);
    color: white;
    width: 35px;
    height: 35px;
    font-size: 1rem;
  }

  @media (max-width: 768px) {
    width: 25px;
    height: 25px;
    font-size: 0.7rem;

    &:hover {
      width: 30px;
      height: 30px;
      font-size: 0.9rem;
    }
  }
`;
